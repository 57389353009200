<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Products</h1>
        </v-col>
        <v-col>
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="accent"
              :to="{ name: 'module-paw-products-create' }"
            >
              <v-icon left>mdi-plus</v-icon>Add Product
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <thead>
              <tr>
                <th
                  v-for="header in tableHeaders"
                  :key="header.text"
                  :class="header.align === 'right' ? 'text-right' : ''"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <draggable v-model="products" tag="tbody">
              <tr v-for="(product, index) in products" :key="index">
                <td>
                  <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
                </td>
                <td>{{ product.display_order }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'module-paw-products-edit',
                      params: { productId: product.id },
                    }"
                  >
                    {{ product.name }}
                  </router-link>
                </td>
                <td>
                  <v-chip
                    label
                    small
                    :color="product.type === 'adult' ? 'warning' : 'success'"
                  >
                    {{ capitalizeFirstLetter(product.type) }}
                  </v-chip>
                </td>
                <td>
                  <div v-html="getProductPriceText(product, 12)"></div>
                </td>
                <td>
                  <div v-html="getProductPriceText(product, 24)"></div>
                </td>
                <td class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="blue lighten-4 blue--text"
                        v-on="on"
                        :to="{
                          name: 'module-paw-products-edit',
                          params: { productId: product.id },
                        }"
                      >
                        <v-icon x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Product</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="red lighten-4 red--text"
                        v-on="on"
                        class="ml-2"
                        @click="deleteProduct(product)"
                      >
                        <v-icon x-small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Product</span>
                  </v-tooltip>
                </td>
              </tr>
            </draggable>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="deleteProductDialog.open"
      max-width="400px"
      @click:outside="resetDeleteProduct()"
    >
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteProductDialog.productName }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert type="error" dense v-if="deleteProductDialog.error"
            >There was an error when attempting to delete this product.</v-alert
          >Are you sure you want to delete this product?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetDeleteProduct()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmDeleteProduct()"
            :loading="deleteProductDialog.loading"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { paw } from "../../mixins/";
import Draggable from "vuedraggable";

export default {
  mixins: [paw],

  components: {
    Draggable,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "", sortable: false },
        { text: "Display Order", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "12kg Prices", value: "12prices", sortable: false },
        { text: "24kg Prices", value: "24prices", sortable: false },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
      productTypes: [
        { value: "adult", label: "Adult" },
        { value: "puppy", label: "Puppy" },
      ],
      deleteProductDialog: {
        open: false,
        error: false,
        productName: "",
        productId: null,
        loading: false,
      },
    };
  },

  computed: {
    products: {
      get() {
        return this.$store.state.paw["products"];
      },
      set(products) {
        const displayOrder = products.map(p => {
          return p.id;
        });
        const appId = this.$route.params.id;

        this.$store.commit("showHideLoader", true);
        this.$store
          .dispatch("paw/updateProductsDisplayOrder", {
            appId,
            displayOrder,
          })
          .then(() => this.$store.commit("showHideLoader", false))
          .catch(() => this.$store.commit("showHideLoader", false));
      },
    },
  },

  methods: {
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deleteProduct: function(product) {
      this.deleteProductDialog.productName = product.name;
      this.deleteProductDialog.productId = product.id;
      this.deleteProductDialog.open = true;
    },
    confirmDeleteProduct: function() {
      const productId = this.deleteProductDialog.productId;
      const appId = this.$route.params.id;
      this.deleteProductDialog.loading = true;
      this.deleteProductDialog.error = false;

      this.$store
        .dispatch("paw/deleteProduct", { appId, productId })
        .then(() => {
          this.resetDeleteProduct();
        })
        .catch(() => {
          this.deleteProductDialog.loading = false;
          this.deleteProductDialog.error = true;
        });
    },
    resetDeleteProduct: function() {
      this.deleteProductDialog = {
        open: false,
        error: false,
        productName: "",
        productId: null,
        loading: false,
      };
    },
  },
};
</script>
