var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Products")])]),_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{attrs:{"depressed":"","color":"accent","to":{ name: 'module-paw-products-create' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Product ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-simple-table',[_c('thead',[_c('tr',_vm._l((_vm.tableHeaders),function(header){return _c('th',{key:header.text,class:header.align === 'right' ? 'text-right' : ''},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('draggable',{attrs:{"tag":"tbody"},model:{value:(_vm.products),callback:function ($$v) {_vm.products=$$v},expression:"products"}},_vm._l((_vm.products),function(product,index){return _c('tr',{key:index},[_c('td',[_c('v-icon',{staticClass:"page__grab-icon",attrs:{"small":""}},[_vm._v("mdi-arrow-all")])],1),_c('td',[_vm._v(_vm._s(product.display_order))]),_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'module-paw-products-edit',
                    params: { productId: product.id },
                  }}},[_vm._v(" "+_vm._s(product.name)+" ")])],1),_c('td',[_c('v-chip',{attrs:{"label":"","small":"","color":product.type === 'adult' ? 'warning' : 'success'}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(product.type))+" ")])],1),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getProductPriceText(product, 12))}})]),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getProductPriceText(product, 24))}})]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"blue lighten-4 blue--text","to":{
                        name: 'module-paw-products-edit',
                        params: { productId: product.id },
                      }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Product")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.deleteProduct(product)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Product")])])],1)])}),0)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400px"},on:{"click:outside":function($event){return _vm.resetDeleteProduct()}},model:{value:(_vm.deleteProductDialog.open),callback:function ($$v) {_vm.$set(_vm.deleteProductDialog, "open", $$v)},expression:"deleteProductDialog.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete "+_vm._s(_vm.deleteProductDialog.productName))]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5"},[(_vm.deleteProductDialog.error)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v("There was an error when attempting to delete this product.")]):_vm._e(),_vm._v("Are you sure you want to delete this product? ")],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.resetDeleteProduct()}}},[_vm._v("No, Cancel")]),_c('v-btn',{attrs:{"color":"accent","text":"","loading":_vm.deleteProductDialog.loading},on:{"click":function($event){return _vm.confirmDeleteProduct()}}},[_vm._v("Yes, Delete")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }